import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Upgrades`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAGOoLzBZ//EABgQAQADAQAAAAAAAAAAAAAAAAIAAQMR/9oACAEBAAEFAsxSm1BGxyf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAADAAMAAAAAAAAAAAAAAAAAAREhMYH/2gAIAQEABj8Cy+CikRtH/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMUFx/9oACAEBAAE/IW60DoRknSyS9P/aAAwDAQACAAMAAAAQcD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAATH/2gAIAQMBAT8Quq//xAAWEQEBAQAAAAAAAAAAAAAAAAABADH/2gAIAQIBAT8QMhv/xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhQWGx/9oACAEBAAE/EHifJhBmiyg+D3dmBYk3/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/d1970b2ab8f2ef3cf1abac90757d63b1/e93cc/StaircaseOffice_Cut.webp 300w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/b0544/StaircaseOffice_Cut.webp 600w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/68fc1/StaircaseOffice_Cut.webp 1200w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/a2303/StaircaseOffice_Cut.webp 1800w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/4293a/StaircaseOffice_Cut.webp 2400w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/09625/StaircaseOffice_Cut.webp 3000w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/d1970b2ab8f2ef3cf1abac90757d63b1/73b64/StaircaseOffice_Cut.jpg 300w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/3ad8d/StaircaseOffice_Cut.jpg 600w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/6c738/StaircaseOffice_Cut.jpg 1200w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/8b34c/StaircaseOffice_Cut.jpg 1800w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/111a0/StaircaseOffice_Cut.jpg 2400w", "/static/d1970b2ab8f2ef3cf1abac90757d63b1/4284b/StaircaseOffice_Cut.jpg 3000w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/d1970b2ab8f2ef3cf1abac90757d63b1/6c738/StaircaseOffice_Cut.jpg",
            "alt": "Upgrade",
            "title": "Upgrade",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`This How-To section provides comprehensive guidance on upgrading CoreMedia Content Cloud. It covers both business and
technical rationales for regular updates, outlines the upgrade process steps, and offers detailed instructions for upgrading
CoreMedia Content Cloud. Additionally, it includes links to the upgrade guides detailing the necessary changes to restore functionality.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      